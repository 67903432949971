import React, { Component } from "react"
import ContactPerson from "components/contactPerson"
import Arrow from "assets/icons/chevron-down-white.svg"

// styles
import styles from "./disciplineitem.module.scss"

class DisciplineItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
        active: this.props.index == 0 ? true : false
    }

  }
  toggleText = () => {
    this.setState(prevState => ({
      active: !prevState.active,
    }))
  }

  render() {
    const { details } = this.props
    return (
      <div className={styles.DisciplineWrapper}>
        <div
          className={[
            styles.teamDetails,
            this.state.active == true ? styles.teamDetailsActive : "",
          ].join(" ")}
          onClick={this.toggleText}
        >
          <div className={styles.titleContainer}>
            <span>
              <Arrow ref={this.arrow} />
            </span>
            <h4 dangerouslySetInnerHTML={{ __html: details.title }} />
          </div>
          <span dangerouslySetInnerHTML={{ __html: details.price }} />
        </div>
        <div
          className={[
            styles.textContainer,
            this.state.active == true ? styles.textContainerActive : "",
          ].join(" ")}
        >
          <div
            className={styles.teamText}
            dangerouslySetInnerHTML={{ __html: details.content }}
          />
          {details.contactperson != "undefined" ? (
            <ContactPerson details={details.contactperson} />
          ) : (
            ""
          )}
        </div>
      </div>
    )
  }
}

export default DisciplineItem
