import React, { Component } from "react"
import anime from "animejs/lib/anime.es.js"
import handleViewport from "react-in-viewport"

import styles from "./blog-intro.module.scss"

class BlogIntro extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transition: null,
    }
  }
  componentDidMount() {
    const transition = anime.timeline({
      easing: "easeOutCubic",
      duration: 800,
      autoplay: false,
    })

    transition.add({
      targets: this.refs.component,
      duration: 400,
      translateY: ["-24px", "0px"],
      opacity: [0, 1],
      easing: "easeInOutCirc(1, .5)",
    })

    this.setState({
      transition,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.inViewport && nextProps.enterCount === 1) {
      this.state.transition.play()
    } else if (!nextProps.inViewport && nextProps.enterCount < 1) {
      // if (this.state.transition && !this.state.transition.reversed) {
      //   this.state.transition.play()
      // }
    }
  }

  render() {
    const { content } = this.props

    return (
      <div ref="component" className={styles.intro}>
        <div
          className={[styles.wrapper, "wrapper", "wrapper--content"].join(" ")}
        >
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    )
  }
}

const BlogIntroTransition = handleViewport(BlogIntro, {
  threshold: 0.5,
})
export default BlogIntroTransition
