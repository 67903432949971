import React, { Component } from "react"
import Image from "gatsby-image"
// styles
import styles from "./contactperson.module.scss"

class contactPerson extends Component {
  render() {
    const { image, email, phone } = this.props.details
    let featuredImage = false

    if (image.src !== null && image.src.localFile) {
      featuredImage = image.src.localFile.childImageSharp.fluid
    }
    return (
      <div className={styles.ContactPersonContainer}>
        <Image
          className={styles.ContactPersonPictue}
          fluid={featuredImage}
        />
        <div className={styles.ContactPersonDetails}>
          <span>
            Bel <b dangerouslySetInnerHTML={{ __html: phone }} /> of mail naar{" "}
            <b dangerouslySetInnerHTML={{ __html: email }} />
          </span>
        </div>
      </div>
    )
  }
}

export default contactPerson
