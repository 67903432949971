import React, { Component } from "react"
import ReactDOM from "react-dom"
import { Link } from "gatsby"
import handleViewport from "react-in-viewport"
import anime from "animejs/lib/anime.es.js"

import ButtonAnimated from "components/ButtonAnimated"

import OurDisciplines from "components/OurDisciplines"
import ContactPerson from "components/contactPerson"

import styles from "./content-block.module.scss"

import Arrow from "assets/icons/arrow.svg"

// TODO: CSS Modules instellen zodat het ook werkt met styles['classname']

class ContentBlock extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transition: null,
    }
  }

  appendArrow = () => {
    let divs = this.refs.component.getElementsByTagName("li")
    if (divs.length > 0) {
      for (let i = 0; i < divs.length; i++) {
        console.log("divs[i]", divs[i].innerHTML)
        const id = Math.random()
        const html = divs[i].innerHTML
        const idContent = Math.random()
        const d = document.createElement("span")
        const content = document.createElement("span")
        divs[i].innerHTML = ""
        d.id = id
        d.classList.add(styles.icon)
        content.id = idContent
        content.classList.add(styles.listItem)
        divs[i].appendChild(content)
        divs[i].appendChild(d)
        ReactDOM.render(
          <span dangerouslySetInnerHTML={{ __html: html }}></span>,
          document.getElementById(idContent)
        )
        ReactDOM.render(
          <Arrow style={{ fill: this.props.color }} key={i} />,
          document.getElementById(id)
        )
      }
    }
  }

  componentDidMount() {
    this.appendArrow()
    const transition = anime.timeline({
      easing: "easeOutCubic",
      duration: 800,
      autoplay: false,
    })

    // transition

    //   .add({
    //     targets: this.refs.component.querySelector(`.${styles.title}`),
    //     duration: 400,
    //     translateY: ["-24px", "0px"],
    //     opacity: [0, 1],
    //     easing: "easeInOutCirc(1, .5)",
    //   })
    //   .add(
    //     {
    //       targets: this.refs.component.querySelector(`.${styles.content}`),
    //       duration: 400,
    //       translateY: ["24px", "0px"],
    //       opacity: [0, 1],
    //       easing: "easeInOutCirc(1, .5)",
    //     },
    //     "-=400"
    //   )

    // this.setState({
    //   transition,
    // })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.inViewport && nextProps.enterCount === 1) {
      this.state.transition.play()
    }
  }
  render() {
    const { title, content, large, link, tabs, contactperson, type, invert } = this.props
    return (
      <div
        className={[
          styles.container,
          large ? styles.large : null,
          type ? styles[`${type}`] : null,
          link ? styles[`hasLink`] : null,
        ].join(" ")}
        ref="component"
      >
        <div
          className={[styles.wrapper, "wrapper", "wrapper--content"].join(" ")}
        >
          <div className={styles.inner}>
            <h3
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div className={styles.content}>
              <div dangerouslySetInnerHTML={{ __html: content }} />
              {link && link.label && (
                <ButtonAnimated
                  type={
                    link.link.includes("#") || link.link.includes("http")
                      ? "external"
                      : null
                  }
                  to={`${link.link}/`}
                  text={link.label}
                  invert={!!invert}
                />
              )}
              {tabs && tabs.length >= 1 ? ( tabs[0].title != "" ?
                  <OurDisciplines details={tabs} /> : ""
              ) : (
                ""
              )}
              {/* {contactperson && contactperson.email && (
                <ContactPerson details={contactperson} />
              )} */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const ContentBlockTransition = handleViewport(ContentBlock, {
  threshold: 0.3,
})
export default ContentBlock
