import React, { Component } from "react"
import anime from "animejs/lib/anime.es.js"
import Image from "gatsby-image"
import ReactHtmlParser from 'react-html-parser'

import styles from "./blog-hero.module.scss"

class BlogHero extends Component {
  componentDidMount() {
    const tl = anime.timeline({
      easing: "easeOutCubic",
      duration: 1500,
    })

    tl.add(
      {
        targets: this.refs.hero.querySelector(`.${styles.image}`),
        opacity: [0, 1],
        duration: 400,
      },
      100
    )
      .add({
        targets: this.refs.hero.querySelector(`.${styles.background}`),
        height: ["0%", "100%"],
        duration: 400,
      })
      .add(
        {
          targets: this.refs.hero.querySelector(`.${styles.reveal}`),
          height: ["100%", "0%"],
          duration: 400,
          delay: 200,
        },
        "-=200"
      )
      .add(
        {
          targets: this.refs.hero.querySelectorAll(
            `.${styles.title} span > span`
          ),
          translateY: [
            this.props.details.title.length === 1 ? "324px" : "172px",
            "0px",
          ],
          duration: 1200,
          easing: "easeInOutExpo",
          delay: anime.stagger(100),
        },
        "-=800"
      )
      .add(
        {
          targets: this.refs.hero.querySelector(`.${styles.name}`),
          translateY: ["104px", "0px"],
          // fontSize: ["0em", "1em"],
          duration: 800,
          easing: "easeInOutExpo",
        },
        "-=600"
      )
      .add(
        {
          targets: this.refs.hero.querySelector(`.${styles.date}`),
          translateY: ["104px", "0px"],
          // fontSize: ["0em", "1em"],
          // width: ["%0", "100%"],
          duration: 800,
          easing: "easeInOutExpo",
        },
        "-=600"
      )
      .add(
        {
          targets: this.refs.hero.querySelector(`.${styles.divider}`),
          translateY: ["-41px", "0px"],
          duration: 800,
          easing: "easeInOutExpo",
        },
        "-=600"
      )
      .add({
        targets: this.refs.hero.querySelector(`.${styles.time}`),
        opacity: [0, 1],
        duration: 800,
        easing: "easeInOutExpo",
      })
  }

  render() {
    const { title, image, readingTime, author, date } = this.props.details
    const { type } = this.props

    let featuredImage = false

    if (image.src !== null && image.src.localFile) {
      featuredImage = image.src.localFile.childImageSharp.fluid
    }
    return (
      <section className={styles.hero} ref="hero">
        <div className={[styles.wrapper, "wrapper"].join(" ")}>
          <div className={styles.image}>
            <span className={styles.reveal} />
            {featuredImage && (
              <Image
                fluid={featuredImage}
                className={styles.background}
                alt={image.alt}
                title={image.title}
              />
            )}
          </div>
          <div className={styles.meta}>
            <h1
              className={[
                styles.title,
                title.length === 1 ? styles.titleSimple : null,
              ].join(" ")}
            >
              {title.map((row, i) => (
                <span key={i}>
                  <span>{ ReactHtmlParser (row) }</span>
                </span>
              ))}
            </h1>
            <span className={styles.author}>
              <span className={styles.name}>{author}</span>
              <i className={styles.divider} />
              <span className={styles.date}>{date}</span>
            </span>
            <span className={styles.time}>{readingTime}</span>
          </div>
        </div>
      </section>
    )
  }
}

export default BlogHero
