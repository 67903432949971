import React, { Component } from "react"
import Image from "gatsby-image"
import Helmet from "react-helmet"

// components
import BlogHero from "components/BlogHero"
import BlogIntro from "components/BlogIntro"
import ContentBlock from "components/ContentBlock"
import ContentImage from "components/ContentImage"
import styles from "./blog.module.scss"

class Blog extends Component {
  render() {
    const { hero, blocks, content, author, slug, date } = this.props.details

    return (
      <>
        <Helmet>
          <script type="application/ld+json">{`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://keytoe.nl/${slug}"
              },
              "headline": "Headline",
              "description": "Desceip",
              "image": {
                "@type": "ImageObject",
                "url": "${hero.image.src && hero.image.src.source_url}",
                "width": 600,
                "height": 60
              },
              "author": {
                "@type": "Person",
                "name": "${author.name}"
              },  
              "datePublished": "${date}",
              "dateModified": "${date}"
            }
        `}</script>
        </Helmet>
        <article className={styles.container}>
          <BlogHero details={hero} />
          <div className={[styles.content, "wrapper"].join(" ")}>
            {blocks ? (
              blocks.map((item, i) => {
                switch (item.type) {
                  case "content":
                    return (
                      <ContentBlock
                        key={i}
                        large
                        title={item.block.title}
                        content={item.block.content}
                        link={item.block.link}
                      />
                    )
                  case "image":
                    return (
                      <ContentImage
                        key={i}
                        image={item.block.image}
                        justify="baseline"
                        type={item.block.align}
                      />
                    )
                  case "intro":
                    return <BlogIntro content={item.block.content}/>
                  default:
                }
              })
            ) : (
              <div
                className={styles.old}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
          </div>
        </article>
      </>
    )
  }
}

export default Blog
