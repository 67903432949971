import React, { Component } from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import handleViewport from "react-in-viewport"
import anime from "animejs/lib/anime.es.js"

import styles from "./content-image.module.scss"
// TODO: CSS Modules instellen zodat het ook werkt met styles['classname']

class ContentImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transition: null,
    }
  }
  componentDidMount() {
    const transition = anime.timeline({
      easing: "easeOutCubic",
      duration: 800,
      autoplay: false,
    })

    transition
      .add({
        targets: this.refs.component.querySelector(`.${styles.image}`),
        duration: 400,
        opacity: [0, 1],
        easing: "easeInOutCirc(1, .5)",
      })
      .add({
        targets: this.refs.component.querySelector(`.${styles.overlay}`),
        duration: 400,
        height: ["100%", "0%"],
        background: [this.props.kleur, this.props.kleur],  
        easing: "easeInOutCirc(1, .5)",
      })

    this.setState({
      transition,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.inViewport && nextProps.enterCount === 1) {
      this.state.transition.play()
    }
  }

  render() {
    const { justify, type, image } = this.props

    let featuredImage = {}

    if (image.src !== null && image.src.localFile) {
      featuredImage = image.src.localFile.childImageSharp.fluid
    }

    return (
      <div
        ref="component"
        className={[styles.container, styles[type], "wrapper"].join(" ")}
        style={{ justifyContent: justify }}
      >
        <div className={[styles.wrapper].join(" ")}>
          <div className={styles.wrap}>
            <span className={styles.overlay} />
            <Image
              fluid={featuredImage}
              alt={image.src && image.src.alt_text}
              title={image.src && image.src.title}
              className={styles.image}
            />
          </div>
        </div>
      </div>
    )
  }
}
const ContentImageTransition = handleViewport(ContentImage, {
  threshold: 0.3,
})
export default ContentImageTransition
