import React, { Component } from "react"
import DisciplineItem from "components/DisciplineItem"

// styles
import styles from "./ourdisciplines.module.scss"

class OurDisciplines extends Component {
  
  render() {
    const { details } = this.props
    return (
      <div className={styles.DisciplineContainer}>
        {details.map((tab, i) => {
          return (
            <DisciplineItem key={i} index={i} details={tab} />
          )
        })}
      </div>
    )
  }
}

export default OurDisciplines